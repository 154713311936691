<template>
    <el-col :span="24" style="background-color: #ffffff">
        <el-col :span="24">
            <div class="container">
                <div class="handle-box">
                    <el-select
                        v-model="schoolIndex"
                        placeholder="学校"
                        style="float: left; margin-right: 10px"
                        :disabled="searchSchoolState"
                        @change="changeSchool"
                    >
                        <el-option v-for="item in schoolIndexList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                    <!-- <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button> -->
                    <el-button
                        style="margin-right: 10px"
                        type="primary"
                        icon="el-icon-plus"
                        @click="addUserBut"
                        class="handle-del mr10 ml10"
                        >添加学校审核员</el-button
                    >
                </div>
                <div class="handle-box"></div>
                <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
                    <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
                    <el-table-column prop="schoolName" label="学校名" align="center"></el-table-column>
                    <el-table-column prop="adminUCN" label="审核员统一ID" align="center"></el-table-column>
                    <el-table-column prop="adminName" label="审核员姓名" align="center"></el-table-column>
                    <el-table-column prop="adminDesc" label="审核员描述" align="center"></el-table-column>
                    <el-table-column label="操作" align="center" style="display: flex; justify-content: center; align-items: center">
                        <template slot-scope="scope" style="display: flex" @click="handleSelect(scope.row)">
                            <div style="display: flex" align="center">
                                <!-- <el-button
                                    type="text"
                                    style="margin-right: 10px"
                                    icon="el-icon-edit"
                                    @click="reviseUserBut(scope.$index, scope.row)"
                                    >修改</el-button
                                > -->
                                <el-button type="text" icon="el-icon-delete" class="red" @click="handledelete(scope.$index, scope.row)"
                                    >删除</el-button
                                >
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.pageIndex"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                    ></el-pagination>
                </div>
            </div>
        </el-col>
        <!-- 二级院校添加弹出框 -->
        <el-dialog title="二级院校添加" :visible.sync="addUserstate" width="30%">
            <el-form ref="form" :model="addUser" label-width="150px">
                <el-form-item label="学校" :required="true">
                    <el-select
                        v-model="schoolIndex"
                        placeholder="请选择学校"
                        @change="changeSchool"
                        style="width: 100%"
                        :disabled="searchSchoolState"
                    >
                        <el-option v-for="item in schoolIndexList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="addUser" label-width="150px">
                <el-form-item label="学校审核员" :required="true">
                    <el-input v-model="addUser.ucn" @change="getUserInfo" placeholder="请输入华立园大门统一ID"></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="addUser" label-width="150px">
                <el-form-item label="审核员姓名" :required="true">
                    <el-input v-model="userName" disabled placeholder="输入华立园大门统一ID自动识别填充"></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="addUser" label-width="150px">
                <el-form-item label="审核员描述" :required="true">
                    <el-input v-model="addUser.desc" disabled placeholder="输入华立园大门统一ID自动识别填充"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addUserstate = false">取 消</el-button>
                <el-button type="primary" @click="addUserOk">确定</el-button>
            </span>
        </el-dialog>
        <!-- 二级院校修改弹出框 -->
        <el-dialog title="二级院校修改" :visible.sync="reviseUserstate" width="30%">
            <el-form ref="form" :model="addUser" label-width="150px">
                <el-form-item label="学校" :required="true">
                    <el-select
                        v-model="schoolIndex"
                        placeholder="请选择学校"
                        @change="changeSchool"
                        style="width: 100%"
                        :disabled="searchSchoolState"
                    >
                        <el-option v-for="item in schoolIndexList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="addUser" label-width="150px">
                <el-form-item label="二级院校审核员" :required="true">
                    <el-input
                        v-model="addUser.ucn"
                        @change="getUserInfo"
                        @input="inputChange($event)"
                        placeholder="请输入华立园大门统一ID"
                    ></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="addUser" label-width="150px">
                <el-form-item label="审核员姓名" :required="true">
                    <el-input v-model="userName" disabled placeholder="输入华立园大门统一ID自动识别填充"></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="addUser" label-width="150px">
                <el-form-item label="审核员描述" :required="true">
                    <el-input v-model="addUser.desc" disabled placeholder="输入华立园大门统一ID自动识别填充"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="reviseUserstate = false">取 消</el-button>
                <el-button type="primary" @click="reviseUserOk">确定</el-button>
            </span>
        </el-dialog>
    </el-col>
</template>

<script>
import { fetchData } from '../../api/index';
export default {
    name: 'basetable',
    data() {
        return {
            query: {
                pageIndex: 1,
                pageSize: 10
            },
            tableData: [],
            pageTotal: 0,
            addUserstate: false,
            addUser: {},
            reviseUserstate: false,
            reviseUser: {},
            searchSchoolState: false,
            userName: '',
            schoolIndex: ''
        };
    },
    activated() {},
    created() {
        var that = this;
        var schoolList = [];
        var userType = JSON.parse(window.sessionStorage.getItem('userType'));
        for (var i = 0; i < userType.length; i++) {
            var userTypeObj = {
                value: userType[i].schoolId,
                label: userType[i].schoolName
            };
            schoolList.push(userTypeObj);
        }
        this.schoolIndexList = schoolList;
        this.schoolIndex = userType[0].schoolId;
        if (this.schoolIndexList.length <= 1) {
            this.searchSchoolState = true;
        }
        this.getgoodList();
    },
    methods: {
        // 换取用户信息
        getUserInfo() {
            var that = this;
            var query = {
                data: {
                    ucn: this.addUser.ucn
                },
                url: '/get_ucnInfo.cls.php'
            };
            fetchData(query).then((res) => {
                if (res.code == 0) {
                    that.userName = res.data.name;
                    that.addUser.desc = res.data.desc;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 删除操作
        handledelete(index, row) {
            console.log(row);
            var that = this;
            // 二次确认删除
            this.$confirm('确定要删除该学校审核员吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    var query = {
                        data: {
                            approId: row.id
                        },
                        url: '/delete_schoolAppro.cls.php'
                    };
                    fetchData(query).then((res) => {
                        if (res.code == 0) {
                            that.$message.success(res.msg);
                            that.getgoodList();
                        } else {
                            that.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        // 获取二级院校数据
        getgoodList() {
            var that = this;
            var query = {
                data: {
                    page: that.query.pageIndex,
                    limit: that.query.pageSize,
                    schoolId: that.schoolIndex
                },
                url: '/get_schoolApproList.cls.php'
            };
            fetchData(query).then((res) => {
                that.tableData = res.data;
                that.pageTotal = Number(res.count) || 0;
            });
        },
        // 监听学校选择器
        changeSchool() {
            this.getgoodList();
        },
        // 用户搜索
        handleSearch() {
            (this.pageIndex = 1), this.getgoodList();
        },
        // 二级院校修改
        reviseUserBut(index, row) {
            console.log(row);
            var that = this;
            this.addUser.college = row.collegeName;
            this.collegeId = row.id;
            this.reviseUserstate = true;
        },
        // 二级院校修改上传
        reviseUserOk() {
            var that = this;
            if (that.addUser.college == undefined || that.addUser.college == '') {
                this.$message.error('二级院校不能为空');
            } else if (that.addUser.desc == undefined || that.addUser.desc == '') {
                this.$message.error('统一ID有误');
            } else {
                var query = {
                    data: {
                        schoolId: that.schoolIndex,
                        name: that.addUser.college,
                        ucn: that.addUser.ucn,
                        collegeId: that.collegeId
                    },
                    url: '/update_collegeInfo.cls.php'
                };
                fetchData(query).then((res) => {
                    if (res.code == 0) {
                        this.reviseUserstate = false;
                        this.$message.success(res.msg);
                        this.getgoodList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        // 二级院校添加
        addUserBut() {
            this.addUserstate = true;
        },
        // 二级院校上传
        addUserOk() {
            var that = this;
            if (that.addUser.desc == undefined || that.addUser.desc == '') {
                this.$message.error('统一ID有误');
            } else {
                var query = {
                    data: {
                        schoolId: that.schoolIndex,
                        name: that.addUser.college,
                        ucn: that.addUser.ucn
                    },
                    url: '/create_schoolAppro.cls.php'
                };
                fetchData(query).then((res) => {
                    if (res.code == 0) {
                        this.addUserstate = false;
                        this.$message.success(res.msg);
                        this.getgoodList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },

        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val;
            this.$set(this.query, 'pageIndex', val);
            this.getgoodList();
        },
        inputChange() {
            this.$forceUpdate();
        }
    }
};
</script>

<style scoped>
.el-cascader {
    width: 90%;
    padding-right: 10px;
}
.demonstration {
    color: #948c8c;
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.block2 {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
</style>
